import React, { useState } from "react";
import logo from "../../assets/images/Logo 2.svg";
import { Link } from "react-router-dom";
import menu from "../../assets/svg/menu.svg";
import closeMenu from "../../assets/svg/close-svgrepo-com.svg";
import Link12 from "./Link12";

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [navLinkClicked, setNavLinkClicked] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleNavLinkClick = () => {
    setNavLinkClicked(true);
  };


  const NavLink = ({ to, children }) => {
    return (
      <Link
        to={to}
        className="btn cursor-pointer hover:text-[#14A2F1] duration-200 ease-in-out"
        onClick={handleNavLinkClick}
      >
        {children}
      </Link>
    );
  };

  return (
    <div className="tran shadow-sm py-4 fixed w-full z-50">
      <div className="md:flex items-center justify-between mx-4 md:mx-24">
       <Link to='/'> <img className="md:block hidden cursor-pointer" src={logo} alt="Logo" /></Link>

        {/* Desktop Navigation */}
        <ul
          className={`hidden md:flex items-center gap-8 ${
            navLinkClicked ? "hidden" : ""
          }`}
        >
          <NavLink to="/">Home</NavLink>
          {/* <NavLink to="/about">About Us</NavLink> */}
          <NavLink to="/recent">Recent Work</NavLink>
          <NavLink to="/founders">Founders</NavLink>
          <Link12 />
        </ul>

        {/* Mobile Menu Icon */}
        <div className="md:hidden flex items-center justify-between">
          <img className="md:hidden cursor-pointer" src={logo} alt="Logo" />
          <img
            src={isMenuOpen ? closeMenu : menu}
            alt="Menu"
            className="md:hidden cursor-pointer w-[33px]"
            onClick={toggleMenu}
          />
        </div>
      </div>

      {/* Mobile Navigation */}
      {isMenuOpen && (
        <div className="md:hidden flex flex-col items-center gap-8 py-4">
          <NavLink to="/">Home</NavLink>
          {/* <NavLink to="/about">About Us</NavLink> */}
          <NavLink to="/recent">Recent Work</NavLink>
          <NavLink to="/founders">Founders</NavLink>
          <Link12 />
        </div>
      )}
    </div>
  );
};

export default Header;
