import React from "react";
import { FaLinkedin } from "react-icons/fa6";
import Ameer from "../../assets/images/Ameer.png";
import Hashim from "../../assets/images/Hashim.png";
const Founders = () => {
  return (
   <div className="w-[80%] mx-auto">
      <h1 className="md:text-[44px] text-[33px] font-[700] pt-20">FOUNDERS</h1>
     <div className=" flex flex-col md:flex-row  gap-12 mt-12">
      {/* left */}          
      <div className="">
        <img className=" md:w-[700px] md:h-[600px] object-cover" src={Hashim} alt="" />
        <div className=" flex flex-col gap-1 mt-4">
          <div className=" flex items-center justify-between">
            <h1 className=" md:text-[33px] text-[25px] font-[700]">Hashim Khan</h1>
            <div className=" flex items-center gap-4">
              <a className=" md:text-[22px]" href="https://www.linkedin.com/in/hashim-khan-7945041a3?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app">
                <FaLinkedin />
              </a>

            </div>
          </div>
          <h3 className=" text-[#34a1db] text-[200] font-[500]">Chief Executive Officer</h3>
          <p className=" text-[17px] text-[#7f7b7b] font-[500]">hashimktk2197@gmail.com</p>
        </div>
      </div>
      {/* Right */}
      <div className="">
        <img className=" md:w-[700px] md:h-[600px] object-cover" src={Ameer} alt="" />
        <div className=" flex flex-col gap-1 mt-4">
          <div className=" flex items-center justify-between">
            <h1 className=" md:text-[33px] text-[25px] font-[700]">Ameer Kakar</h1>
            <div className=" flex items-center gap-4">
              <a className=" md:text-[22px]" href="https://www.linkedin.com/in/ameer-kakar-0316?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app">
                <FaLinkedin />
              </a>
            </div>
          </div>
          <h3 className=" text-[#34a1db] text-[200] font-[500]">Chief Technology Officer</h3>
          <p className=" text-[17px] text-[#7f7b7b] font-[500]">ameerkakar17@gmail.com</p>
        </div>
      </div>

    </div>
   </div>
  );
};

export default Founders;
